.about {
  background-color: $color-bg-secondary;
  box-sizing: border-box;
  height: calc(110vh - 60px);
  width: 100%;
  scroll-margin-top: 60px;

  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    text-align: center;
    height: 100%;
    width: 100%;
  }

  &__flex-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    gap: 30px;
    width: 100%;
    z-index: 10;

    @include tablet {
      position: relative;
      width: 50%;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 45px;
    height: 100%;
    width: 80%;
  }

  &__list-item {
    display: flex;
    align-items: center;
    text-align: start;
    gap: 20px;

    &__description {
      width: 100%;
    }
  }

  &__img-container {
    background-image: url("../../assets/images/ana-014.png");
    background-size: cover;
    background-position: center;
    background-position-y: 30%;
    background-attachment: scroll;
    height: 100%;
    width: 100%;

    @include tablet {
      width: 50%;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: hsl(24, 5%, 18%); 
      opacity: 0.5;

      @include tablet {
        opacity: 0.1;
      }
    }
  }

  &__title {
    font-family: $font-family-secondary;
    font-size: 30px;
    font-weight: $font-weight-bold;
    color: $color-light;
    line-height: $line-height-titles;
    text-align: center;
  }
}