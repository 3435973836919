.divider {
  width: 80%;
  margin: 0 auto;
  padding: 20px 0;

  &__dark {
    border-bottom: 2px solid $color-hazel;
  }

  &__light {
    border-bottom: 1px solid $color-hazel-light;
  }
}