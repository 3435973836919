.features {
  background-color: $color-bg-secondary;
  box-sizing: border-box;
  height: 110vh;
  width: 100%;
  position: relative;
  
  &__content {
    background-image: url("../../assets/images/ana-038.png");
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
    background-position-y: 50%;
    height: 100%;
    width: 100%;

    @include tablet {
      background-attachment: fixed;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: hsl(24, 5%, 18%); 
      opacity: 0.7;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    padding: 70px 20px;
    text-align: center;
    height: 100%;
    width: 100%;
    z-index: 10;

    @include tablet {
      padding: 130px 80px;
    }
  }

  &__title {
    text-align: center;
    font-family: $font-family-secondary;
    font-size: 30px;
    font-weight: $font-weight-bold;
    color: $color-light;
    line-height: $line-height-titles;
  }

  &__grid-container {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 30px;
    padding-top: 40px;

    @include tablet {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      row-gap: 90px;
      column-gap: 50px;
      padding-top: 60px;
    }
  }
}

.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__icon {
    opacity: 0.8;
    width: 70px;
    height: auto;
  }

  &__description {
    margin-top: 17px;
    color: $color-grey;
    line-height: $line-height;
    font-size: $font-size;
    max-width: 500px;
  }
}
