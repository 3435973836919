.btn {
  display: inline-block;
  min-width: 200px;
  padding: 16px 0;
  background-color: transparent;
  border-radius: 5px;
  color: $color-light;
  border: $color-grey 1px solid;
  cursor: pointer;
  font-weight: bold;

  &:hover {
    opacity: 0.6;
    transition: opacity 0.3s ease-in-out;
  }
}