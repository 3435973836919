.footer {
  width: 100%;
  background-color: $color-bg-primary;
  color: $color-grey;

  &__content {
    margin: 0 auto;
    padding: 140px 30px 30px 30px;
    width: 80%;
  }

  &__flex-outer {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include tablet {
      flex-direction: row;
      column-gap: 140px;
      align-items: flex-start;
    }
  }

  &__flex-inner {
    min-width: fit-content;
  }

  &__copyright {
    text-align: start;
    font-size: 13px;
    padding-top: 100px;
    opacity: 0.8;

    @include tablet {
      text-align: center;
    }
  }

  .logo {
    margin-left: 0;
  }
}