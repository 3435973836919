.logo {
  line-height: 1;

  &__item {
    display: inline-block;
    color: $color-grey;
    font-size: 17px;
    letter-spacing: 1px;
    font-family: "DM Serif Text", serif;
    font-weight: 700;
    font-style: normal;

    &:not(:first-child) {
      margin-left: 5px;
    }

    &::first-letter {
      font-size: 22px;
    }
  }
}