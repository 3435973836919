.form {
  all: unset;
  margin-top: 20px;
  width: 100%;
  @include tablet {
    width: 50vw;
  }

  &__content {
    width: 100%;
    border: none;
  }

  &__flex-container {
    display: flex;
    // flex-direction: column;
    justify-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 1;
  }

  &__label {
    display: inline-block;
    margin: 10px;
  }

  &__form-item {
    width: 48%;
    padding: 5px 0;

    &:nth-child(5) {
      width: 100%;
    }
  }

  &__text-input {
    all: unset;
    box-sizing: border-box;
  }

  &__text-input::placeholder {
    padding-left: 10px;
    font-size: 12px;
  }

  &__btn {
    align-self: center;
    padding: 16px 12px;
    margin-top: 20px;
  }
}