.link {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  
    &:hover {
      color: $color-light;
      transition: all 0.3s ease-out;
      text-decoration: underline;
    }

    &--secondary:hover {
      color: $color-light;
      transition: all 0.3s ease-out;
      text-decoration: none;
    }
  }