body {
  // height: 100vh;
  background-color: $color-bg-secondary;
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight-normal;
  color: $color-grey;
}

.lazy {
  background-image: none;
}

html, body {
    font-family: Arial, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}