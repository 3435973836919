.footer-menu {
  margin-top: 80px;
  @include tablet {
    margin-top: 0;
  }
  
  &:nth-of-type(2) {
    margin-top: 50px;

    @include tablet {
      margin-left: 80px;
      margin-top: 0;
    }
    @include desktop {
      margin-top: 0;
      margin-right: 40px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    justify-content: center;
  }

  &__list-item:not(:first-child) {
    margin-top: 20px;
  }
}