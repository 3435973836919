.hero {
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    min-height: calc(100vh - 70px);
    width: 100%;

    @include desktop {
      width: 100%;
    }
  }

  &__img-background {
    background-image: url("../../assets/images/ana-001.png");
    background-size: cover;
    background-position: top;
    background-position-y: 30%;
    position: absolute;
    right: 0;
    top: 70px;
    height: 100%;
    width: 100%;

    @include tablet {
      width: 70%;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: hsl(24, 5%, 18%); 
      opacity: 0.1;
    }
  }

  &__text-container {
    background-color: $color-bg-tertiary-opaque;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
    margin-top: 70%;
    height: 100%;
    width: 100%;
    z-index: 10;
    
    @include tablet {
      padding: 40px 40px;
      margin-top: 230px;
      margin-left: 80px;
      width: 40%;
    }
  }

  &__title {
    font-family: $font-family-secondary;
    font-size: $font-size-titles;
    font-weight: $font-weight-bold;
    color: $color-light;
    line-height: $line-height-titles;

    @include desktop {
      font-size: 35px;
    }
  }

  &__description {
    color: $color-grey;
    line-height: $line-height;
    font-size: $font-size;
  }

  &__link {
    margin-top: 30px;
    color: $color-light;
    text-decoration: none;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-self: center;

    @include desktop {
      flex-direction: row;
      gap: 20px;
    }
  }
}