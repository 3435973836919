.demo {
  background-color: $color-bg-secondary;
  box-sizing: border-box;
  height: 110vh;
  width: 100%;
  position: relative;

  &__content {
    background-image: url("../../assets/images/ana-100.png");
    background-size: cover;
    background-position: top;
    background-attachment: scroll;
    background-position-y: 15%;
    height: 100%;
    width: 100%;

    @include tablet {
      background-attachment: fixed;
      text-align: start;
      gap: 50px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: hsl(24, 5%, 18%); 
      opacity: 0.7;
    }
  }

  &__text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 10% 5%;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 10;

    @include tablet {
      padding: 20% 20%;
    }
  }

  &__title {
    font-family: $font-family-secondary;
    font-size: 30px;
    font-weight: $font-weight-bold;
    color: $color-light;
    line-height: $line-height-titles;
  }

  &__description {
    margin-top: 14px;
    color: $color-grey;
    line-height: $line-height;
    font-size: 18px;

    &__bold {
      font-weight: $font-weight-bold;
    }
  }


  &__link {
    margin-top: 14px;
    padding-bottom: 7px;
    font-size: 13px;
    border-bottom: 1px solid $color-cta;
    color: $color-cta;
    display: inline-block;
    text-decoration: none;
    &:hover {
      color: $color-light;
      border-bottom: 1px solid $color-light;
      transition: all 0.3s ease-out;
    }
  }

  &__icon {
    display: inline-block;
    height: 16px;
    min-width: 0;
    padding-left: 5px;
    transform: translateY(4px);
    &:hover {
      color: $color-light;
      transition: all 0.3s ease-out;
    }
  }
}