.registration {
  position: relative;
  scroll-margin-top: 70px;
  height: calc(110vh - 70px);
  width: 100%;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }

  &__form {
    background-position-x: left;
    border-radius: 5px;
    display: flex;
    padding: 20px 30px;
    flex-direction: column;
    position: absolute;
    z-index: 10;

    @include tablet {
      background-color: $color-bg-tertiary-opaque;
      padding: 40px 40px;
      right: 40px;
    }
  }

  &__img-background {
    display: none;
    position: relative;
    background-image: url("../../assets/images/ana-022.png");
    background-size: 0;
    background-position: left;
    background-attachment: scroll;
    height: 100%;
    width: 100%;

    @include tablet {
      display: initial;
      background-size: cover;
      background-attachment: fixed;
      width: 50%;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: hsl(24, 5%, 18%); 
      opacity: 0.7;

      @include tablet {
        opacity: 0.1;
      }
    }
  }

  &__title {
    font-family: $font-family-secondary;
    font-size: 25px;
    font-weight: $font-weight-bold;
    color: $color-light;
    line-height: $line-height-titles;
    text-align: center;
  }

  &__text {
    margin-top: 20px;
    color: $color-grey;
    line-height: $line-height;
    font-size: $font-size;
    text-align: center;

    @include tablet {
      max-width: 50vw;
    }
  }
}