@mixin tablet() {
  @media (min-width: 700px) {
    @content;
  }
}

@mixin desktop() {
  @media (min-width: 1200px) {
    @content;
  }
}
