.header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 70px;
  z-index: 20;
  padding: 20px 0;
  background-color: $color-bg-primary;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    font-family: $font-family-secondary;
    line-height: $line-height;
    flex-wrap: wrap;
    row-gap: 5px;

    @include tablet {
      flex-direction: row;
      padding-left: 65px;
      padding-right: 65px;
      row-gap: 0;
    }
  }

  &__title {
    margin: 0;
    line-height: 0;
  }
}

.header-menu {
  &__list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 17px;

    @include tablet {
      align-items: center;
    }
  }
}