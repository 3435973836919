// TYPOGRAPHY
$font-family: 'Open Sans', sans-serif;
$font-family-secondary: 'Raleway', sans-serif; // headings, cta, header nav
$font-size: 16px;
$font-size-titles: 24px;
$line-height: 1.6;
$line-height-titles: 1.5;
$font-weight-normal: 400;
$font-weight-bold: 700;

// COLORS
// background colors
$color-bg: hsl(216, 53%, 74%); 

$color-bg-primary: #8e7e70; 
// $color-bg-secondary:  hsl(120, 7%, 42%); 
// $color-bg-secondary: #a6b1a6;
// $color-bg-secondary:  #868f86; 
$color-bg-secondary:  #68615b;

// $color-bg-tertiary: hsl(210, 18%, 22%); 
$color-bg-tertiary: hsl(24, 5%, 18%); 
$color-bg-tertiary-opaque: hsla(24, 5%, 18%, 0.7); 


// $color-bg-quaternary: #707f70; 
// $color-bg-quaternary: rgb(140, 151, 140); 
// $color-bg-quaternary: hsl(120, 3%, 31%);
$color-bg-quaternary: rgb(142, 132, 125); 


// neutral colors
$color-light: hsl(0, 0%, 88%);
// $color-light: rgb(255, 255, 255);

$color-dark: hsl(0, 0%, 0%);
$color-grey: hsl(0, 0%, 89%);
// $color-grey: rgb(255, 255, 255);


// accent colors
$color-cta: hsl(216, 24%, 16%);
$color-cta-gradient: hsl(212, 20%, 25%);;
$color-error: hsl(0, 100%, 63%);
$color-hazel-light: hsl(116, 19%, 86%);
$color-hazel: #535f53;
$color-hazel-dark: #3f463f;
$color-brown-light: hsl(23, 6%, 75%);

