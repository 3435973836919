.privacy {
  width: 100%;
  padding: 2rem;
  @include tablet {
    padding: 10rem;
    max-width: 60%;
  }

  &__header {
    display: flex;
    align-items: flex-end;
    gap: 15px;
  }

  &__icon {
    width: 30px;
  }

  &__title {
    font-size: $font-size-titles;
    margin: 0;
    padding: 0;
    line-height: 1;
  }
  
  &__content {
    padding-top: 20px;

    .title {
      font-size: 18px;
      font-weight: 900;
    }

    .text {
      padding-top: 15px;
      line-height: 20px;
    }

    span {
      font-weight: bolder;
      text-decoration: underline;
    }
  }
}