.contact-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;

  @include tablet {
    justify-content: flex-start;
    column-gap: 70px;
  }

  &__flex-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__image {
    display: inline-block;
    height: auto;
    font-size: 14px;
    margin-right: 18px;
  }

  &__text {
    max-width: 300px;
    line-height: $line-height;
  }
}
